/* You can add global styles to this file, and also import other style files */


/* Margins */

.m-t-xs {
    margin-top: 5px;
}

.m-t-sm {
    margin-top: 10px;
}

.m-t-lg {
    margin-top: 20px;
}

.m-t-25 {
    margin-top: 25px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-b-xs {
    margin-bottom: 5px;
}

.m-b-sm {
    margin-bottom: 10px;
}

.m-b-md {
    margin-bottom: 15px;
}

.m-b-lg {
    margin-bottom: 20px;
}

.m-b-xl {
    margin-bottom: 25px;
}

.m-l-xs {
    margin-left: 5px;
}

.m-l-sm {
    margin-left: 10px;
}

.m-l-md {
    margin-left: 15px;
}

.m-l-lg {
    margin-left: 20px;
}

.m-l-xl {
    margin-left: 25px;
}

.m-r-xs {
    margin-right: 5px;
}

.m-r-sm {
    margin-right: 10px;
}

.m-r-md {
    margin-right: 15px;
}

.m-r-lg {
    margin-right: 20px;
}


/*
  Margins
  */

.page-item.active .page-link {
    background-color: #EA7C54;
    border-color: #EA7C54;
    color: white;
}

.form-item label {
    width: 100%;
}

.btn-primary,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle,
.btn-outline-primary:hover {
    background-color: #EA7C54;
    border-color: #EA7C54;
    color: white;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background-color: white;
    border-color: #EA7C54;
    color: #EA7C54;
}

.btn-primary.focus,
.btn-primary:focus {
    background-color: white;
    border-color: #EA7C54;
    color: #EA7C54;
    box-shadow: 0 0 0 0.2rem rgb(234 124 84 / 50%);
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    background-color: white;
    border-color: #EA7C54;
    color: #EA7C54;
    box-shadow: 0 0 0 0.2rem rgb(234 124 84 / 50%);
}

.btn-primary:hover,
.btn-outline-primary {
    background-color: white;
    border-color: #EA7C54;
    color: #EA7C54;
}

.hidden {
    opacity: 0;
    max-width: 0px;
    max-height: 0px;
    height: 0px;
    width: 0px;
    overflow: hidden;
}